/* stylelint-disable selector-max-id */
@import "./fonts";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
//Colors
$primary: #136ef6;
$body-secondary-bg: #ebf1f9;
$body-tertiary-bg: #f5f8fc;
$body-bg-dark: $gray-900;
$body-secondary-bg-dark: #16191c;
$body-tertiary-bg-dark: mix($body-bg-dark, $body-secondary-bg-dark, 50%);

// Options
$focus-ring-width: 0.125rem;

$btn-box-shadow: none;
$btn-border-radius: var(--#{$prefix}border-radius-xxl);
$btn-border-radius-sm: var(--#{$prefix}border-radius-xl);
$btn-border-radius-lg: var(--#{$prefix}border-radius-xxl);

$form-label-margin-bottom: $spacer * 0.25;
$form-label-font-size: $font-size-sm;

$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 2;
$card-border-width: 0;
$card-border-radius: $border-radius-xl;
$card-cap-bg: $card-bg;

$modal-inner-padding: $spacer * 2;
$modal-header-padding: $modal-inner-padding;
$modal-content-border-radius: var(--#{$prefix}border-radius-xl);
$modal-header-border-width: 0;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;

$dropdown-padding-x: 0.25rem;
$dropdown-padding-y: 0.25rem;

@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "./max-w";

@import "./min-w";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";

@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
// @import 'bootstrap/scss/card';
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
// @import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";

// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";

// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";

// @import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";

:root {
  --#{$prefix}dropdown-item-border-radius: #{$border-radius-sm};
}

html,
body,
#main {
  min-height: 100vh;
}

.bi {
  display: inline-block;
  vertical-align: -0.125em;
}

.form-group {
  min-height: #{$spacer * 6};
}

$logo-height: 2rem;
$navbar-height: $logo-height + $navbar-brand-padding-y * 2 + $navbar-padding-y * 2;

.logo-height {
  height: #{$logo-height};
}

.embed-code {
  font-family: var(--#{$prefix}font-monospace);
  line-height: #{$line-height-sm};
  font-size: #{$font-size-sm};
}

@import "./page-wrapper";
@import "./btn-nofill";
@import "./btn-icon-only";
@import "./snippet-card";
@import "./new-snippet";
@import "./input-group-inline-button";
@import "./toolbar";
@import "./animations";
@import "./xterm";
@import "./editor-page";
@import "./embed-page";
@import "./btn-run.scss";
@import "./badge-snippet";

@include media-breakpoint-down(sm) {
  .btn-lg {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  }
}
