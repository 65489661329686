.btn-run {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.running {
    mask-image: linear-gradient(120deg, $black 40%, rgba(0, 0, 0, 0.8) 75%, $black 95%);
    mask-size: 200% 100%;
    animation: btn-run 2s linear infinite;
  }

  .bi {
    font-size: 1.25rem;
  }
}

@keyframes btn-run {
  100% {
    mask-position: -200% 0%;
  }
}
