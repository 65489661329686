@mixin button-nofill-variant(
  $color,
  $hover-background: $color,
  $hover-color: color-contrast($hover-background),
  $active-background:
    if($hover-color == $color-contrast-light, shade-color($color, $btn-active-bg-shade-amount), tint-color($color, $btn-active-bg-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-color: $color
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-border-color: transparent;
}

@each $color, $value in $theme-colors {
  .btn-nofill-#{$color} {
    @include button-nofill-variant($value);
  }
}

.btn-nofill-body {
  --#{$prefix}btn-color: var(--#{$prefix}secondary-color);
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: var(--#{$prefix}body-color);
  --#{$prefix}btn-hover-bg: rgba(var(--#{$prefix}body-color-rgb), 0.075);
  --#{$prefix}btn-hover-border-color: transparent;

  --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}tertiary-color-rgb);

  --#{$prefix}btn-active-color: var(--#{$prefix}body-color);
  --#{$prefix}btn-active-bg: rgba(var(--#{$prefix}body-color-rgb), 0.15);
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};

  --#{$prefix}btn-disabled-color: var(--#{$prefix}#secondary-color-rgb);
  --#{$prefix}btn-disabled-border-color: transparent;
}
