$snippet-card-spacer-y: $spacer;
$snippet-card-spacer-x: $spacer;
$snippet-card-gap: $spacer * 0.5;
$snippet-card-border-radius: $border-radius-lg;
$snippet-card-hover-transition: box-shadow 0.2s ease-in-out;
$snippet-card-hover-box-shadow: 0 0.5rem 1rem rgba($black, 0.1);
$snippet-card-bg: $card-bg;
$snippet-card-color: $card-color;

@mixin snippet-card-padding-x {
  padding-right: var(--#{$prefix}snippet-card-spacer-x);
  padding-left: var(--#{$prefix}snippet-card-spacer-x);
}

@mixin snippet-card-padding-y {
  padding-top: var(--#{$prefix}snippet-card-spacer-y);
  padding-bottom: var(--#{$prefix}snippet-card-spacer-y);
}

@mixin form-check-size($size) {
  width: $size;
  height: $size;
}

.snippet-card-wrapper {
  height: 10rem;
}

.snippet-card {
  --#{$prefix}snippet-card-spacer-y: #{$snippet-card-spacer-y};
  --#{$prefix}snippet-card-spacer-x: #{$snippet-card-spacer-x};
  --#{$prefix}snippet-card-gap: #{$snippet-card-gap};
  --#{$prefix}snippet-card-border-radius: #{$snippet-card-border-radius};
  --#{$prefix}snippet-card-hover-box-shadow: #{$snippet-card-hover-box-shadow};
  --#{$prefix}snippet-card-bg: #{$snippet-card-bg};
  --#{$prefix}snippet-card-color: #{$snippet-card-color};

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  color: var(--#{$prefix}snippet-card-color);
  word-wrap: break-word;
  background-color: var(--#{$prefix}snippet-card-bg);
  background-clip: border-box;
  transition: $snippet-card-hover-transition;
  @include snippet-card-padding-y();
  @include border-radius(var(--#{$prefix}snippet-card-border-radius));

  &:hover {
    box-shadow: var(--#{$prefix}snippet-card-hover-box-shadow);
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    gap: var(--#{$prefix}snippet-card-gap);
    align-items: center;
    @include snippet-card-padding-x();

    height: $font-size-base * $line-height-base;

    &-icon {
      width: auto;
      height: $font-size-base * $line-height-base;
    }
  }

  &-body {
    height: 100%;
    overflow: hidden;
    @include snippet-card-padding-x();

    > pre {
      overflow: hidden;
      margin-top: var(--#{$prefix}snippet-card-gap);
      font-size: $font-size-base * 0.5;
      color: var(--#{$prefix}secondary-color);
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding-top: var(--#{$prefix}snippet-card-spacer-y);
    @include snippet-card-padding-x();

    margin-bottom: calc(var(--#{$prefix}snippet-card-spacer-y) / 2);
    @include border-bottom-radius(var(--#{$prefix}snippet-card-border-radius));
    @include gradient-y(#fff0, var(--#{$prefix}snippet-card-bg), 0, 100%);
  }

  .toolbar {
    height: #{$input-height};

    .animated {
      align-self: end;
      transition:
        #{$transition-collapse},
        #{$transition-fade};

      &.opened {
        height: #{$input-height};
        opacity: 1;
      }

      &.collapsed {
        height: 0;
        opacity: 0;
      }
    }
  }

  .form-check {
    @include form-check-size($font-size-base * $line-height-base);
  }

  .form-check-input {
    @include form-check-size($font-size-base * $line-height-sm);
  }
}

.snippet-card button,
.snippet-card input,
.snippet-card a {
  z-index: 1;
}
