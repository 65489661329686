.input-group-inline-button {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding-right: $input-height-inner;

    &.is-valid,
    &.is-invalid {
      padding-right: calc($input-height-inner * 1.75);
      background-position: right calc($input-height-inner) center;
    }
  }

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    width: calc($input-height - $input-padding-y);
    height: calc($input-height - $input-padding-y);
    margin: calc($input-padding-y / 2);
    padding: 0;
    border: none;
    // @include border-start-radius(0);
    // @include border-end-radius($input-border-radius);

    > img,
    > svg {
      display: inline-block;
      vertical-align: -0.2em;
    }
  }
}

.input-group-inline-button-lg > .form-control {
  padding: $input-padding-y-lg $input-height-inner $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  @include border-radius($input-border-radius-lg);
}

.input-group-inline-button-lg > .btn {
  height: $input-height-lg;
  @include font-size($input-font-size-lg);
  @include border-start-radius(0);
  @include border-end-radius($input-border-radius-lg);
}
