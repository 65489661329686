.width-enter {
  opacity: 0;
  width: 0;
}

.width-enter-active {
  opacity: 1;
  transition:
    #{$transition-collapse-width},
    #{$transition-fade};
}

.width-exit {
  opacity: 1;
}

.width-exit-active {
  opacity: 0;
  width: 0;
  transition:
    #{$transition-collapse-width},
    #{$transition-fade};
}

.transition-padding {
  @include transition(padding 0.25s ease);
}

.glow {
  opacity: 0.75;
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  50% {
    opacity: 0.33;
  }
}
