.page-wrapper {
  display: flex;

  // flex-direction: column;
  // flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
}

.page-bg-image {
  position: relative;
  width: 100%;
  background-color: var(--#{$prefix}secondary-bg);

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../../assets/images/snippets-page-bg.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: map-get($grid-breakpoints, md);
    mix-blend-mode: luminosity;
    opacity: 0.3;
  }

  > * {
    position: relative;
  }
}

@include media-breakpoint-up(md) {
  .page-bg-image::before {
    background-size: 100%;
  }
}

@include media-breakpoint-up(xxl) {
  .page-bg-image::before {
    background-size: map-get($grid-breakpoints, xxl);
  }
}
