@font-face {
  font-family: Onest;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Onest-Light.woff");
}

@font-face {
  font-family: Onest;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Onest-Regular.woff");
}

@font-face {
  font-family: Onest;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Onest-Medium.woff");
}

@font-face {
  font-family: Onest;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Onest-Bold.woff");
}

$font-family-sans-serif: onest, sans-serif;
