.new-snippet {
  display: flex;
  flex-flow: column nowrap;
  gap: #{$snippet-card-spacer-y};
  align-items: center;
  justify-content: center;
  border: $border-width dashed var(--#{$prefix}border-color-translucent);
  transition: $transition-base;
  @include border-radius($snippet-card-border-radius);
  @include snippet-card-padding-x();
  @include snippet-card-padding-y();

  &:hover {
    background-color: $snippet-card-bg;
    border-color: $snippet-card-bg;
    opacity: 1;
  }

  .new-snippet-links {
    display: flex;
    flex-flow: row;
    gap: #{$snippet-card-spacer-y};
    align-items: center;
    justify-content: center;

    .btn {
      height: 3rem;
      padding: 0;
      overflow: hidden;
      filter: grayscale(1);
      opacity: 0.5;
      transition: $transition-base;
      @include border-radius($border-radius-sm);

      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }

      img,
      svg {
        width: auto;
        height: 100%;
      }
    }
  }
}
