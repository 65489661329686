.embed-page-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - $navbar-height);
  max-height: calc(100vh - $navbar-height);

  .embed-page-panel {
    flex: 0 0 50%;
    overflow: hidden;
  }
}

@include media-breakpoint-up(md) {
  .embed-page-wrapper {
    flex-direction: row;
  }
}
