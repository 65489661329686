.badge-snippet {
  --#{$prefix}badge-padding-x: #{$btn-padding-x};
  --#{$prefix}badge-padding-y: #{$btn-padding-y};
  --#{$prefix}badge-font-size: #{$font-size-base};
  --#{$prefix}badge-font-weight: #{$font-weight-normal};
  line-height: 1.5;
}

 .sticky-bottom {
  bottom: $spacer;
 }