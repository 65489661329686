@mixin btn-icon-only {
  line-height: 1;

  > * {
    display: block;
  }
}

.btn-icon-only {
  @include btn-icon-only();

  padding: var(--#{$prefix}btn-padding-y);
  font-size: var(--#{$prefix}btn-font-size);
}

.btn-icon-only-full-height {
  @include btn-icon-only();

  padding: calc(var(--#{$prefix}btn-font-size) * 0.125 + var(--#{$prefix}btn-padding-y));
  font-size: calc(var(--#{$prefix}btn-font-size) * 1.25);
}
