.toolbar {
  display: flex;
  flex-flow: row wrap;
  gap: $spacer * 0.25;
  align-items: center;

  .toolbar-indicator {
    padding: $spacer * 0.25;
    line-height: 1;
    font-size: calc(#{$font-size-base} * 1.25);
  }
}
