:root {
  --color-grey: #6d757d;
  --color-white: #ffffff;
}

.footerNavLink {
  --bs-nav-link-color: var(--color-grey);
}

.footerLink {
  --bs-link-color: var(--color-grey);
  --bs-link-hover-color: var(--color-white);
}
